import React from 'react';
import { graphql } from 'gatsby';
import { Grid, Row, Col, Box, Typography } from '@smooth-ui/core-sc';

import Slider from 'components/Slider';
import BlockContent from 'components/block-content';
import Mask from 'components/Mask';
import SEO from 'components/seo';
import Layout from 'components/Layout';
import Card from 'components/Card';
import Contact from 'components/Forms/Contact';

export const query = graphql`
  query InsurancePageQuery {
    sanityInsurance {
      intro_title
      _rawIntroCopy
      intro_image {
        asset {
          small: fixed(toFormat: JPG, width: 800, height: 960) {
            src
          }
          large: fixed(toFormat: JPG, width: 553, height: 886) {
            src
          }
        }
      }
      insuranceLogos {
        asset {
          url
        }
      }
      individuals_title
      _rawIndividualsCopy
      individuals_insurance_1_title
      _rawIndividualsInsurance1Copy
      individuals_insurance_2_title
      _rawIndividualsInsurance2Copy
      pros_title
      _rawProsCopy
      pros_insurance_1_title
      _rawProsInsurance1Copy
      pros_insurance_2_title
      _rawProsInsurance2Copy
      contact {
        title
        image {
          asset {
            fixed(toFormat: JPG, width: 565, height: 750) {
              src
            }
          }
        }
      }
      seo {
        title
        description
        image {
          asset {
            fixed(toFormat: JPG, width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
  }
`;

const Block = ({ title, blocks }) => (
  <Col xs={12} lg={6} mb={{ xs: 4, md: 7, lg: 10 }}>
    <Card noHover height={1} display="flex" flexDirection="column">
      <Box
        py={4}
        px={3}
        textAlign="center"
        backgroundColor="primary"
        color="white"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight={{ lg: 250 }}
      >
        <Typography
          fontSize={{ xs: 3, md: 26, lg: 30 }}
          mb={0}
          maxWidth="100%"
          color="green"
          variant="h3"
        >
          {title}
        </Typography>
      </Box>
      <Box p={3} backgroundColor="white" style={{ flexGrow: 1 }}>
        <BlockContent blocks={blocks || []} />
      </Box>
    </Card>
  </Col>
);

const Insurance = ({ data }) => {
  const page = data.sanityInsurance;

  return (
    <Layout>
      <SEO
        title={page.seo.title}
        description={page.seo.description}
        image={page.seo.image.asset.fixed.src}
      />
      <Grid mb={6}>
        <Row mb={{ xs: 4, md: 0 }}>
          <Col xs={12} md={6}>
            <Box mt={'-40%'} mb={{ xs: 50, md: 0 }} ml={{ xs: '-20px', sm: 0 }}>
              <Mask
                large={page.intro_image.asset.large.src}
                small={page.intro_image.asset.small.src}
              />
            </Box>
          </Col>
          <Col mt={{ md: 120 }} xs={12} md={6}>
            <Box>
              <Typography variant="h1" mb={4}>
                {page.intro_title}
              </Typography>
              <BlockContent blocks={page._rawIntroCopy || []} />
            </Box>
          </Col>
        </Row>
      </Grid>
      <Grid mb={{ xs: 6, lg: 12 }}>
        <Card noHover backgroundColor="white" py={9} px={3}>
          <Slider
            className="carousel__slider--insurance"
            slidesToShow={5}
            slidesToScroll={5}
            dots={true}
            arrows={false}
            autoplay={true}
            autoplaySpeed={3000}
            responsive={[
              {
                breakpoint: 960,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
                }
              },
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              }
            ]}
          >
            {page.insuranceLogos.map((logo, i) => (
              <Box key={i} height={1}>
                <Box display="flex" alignItems="center" justifyContent="center" height={1}>
                  <img
                    src={logo.asset.url}
                    style={{
                      maxWidth: '140px',
                      width: 'auto',
                      maxHeight: '40px',
                      margin: '0 auto'
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Slider>
        </Card>
      </Grid>
      <Grid>
        <Row>
          <Col xs={12} md={4}>
            <Box py={{ xs: 4, lg: 9 }} px={3}>
              <Typography variant="h2" fontSize={{ xs: 27, lg: 4 }}>
                {page.individuals_title}
              </Typography>
              <BlockContent blocks={page._rawIndividualsCopy || []} />
            </Box>
          </Col>
          <Col xs={12} md={8}>
            <Row>
              <Block
                title={page.individuals_insurance_1_title}
                blocks={page._rawIndividualsInsurance1Copy}
              />
              <Block
                title={page.individuals_insurance_2_title}
                blocks={page._rawIndividualsInsurance2Copy}
              />
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Box py={{ xs: 4, lg: 9 }} px={3}>
              <Typography variant="h2" fontSize={{ xs: 27, lg: 4 }}>
                {page.pros_title}
              </Typography>
              <BlockContent blocks={page._rawProsCopy || []} />
            </Box>
          </Col>
          <Col xs={12} md={8}>
            <Row>
              <Block title={page.pros_insurance_1_title} blocks={page._rawProsInsurance1Copy} />
              <Block title={page.pros_insurance_2_title} blocks={page._rawProsInsurance2Copy} />
            </Row>
          </Col>
        </Row>
      </Grid>
      <Contact
        formName="Verzekeringen"
        title={page.contact.title}
        image={page.contact.image.asset.fixed.src}
      />
    </Layout>
  );
};

export default Insurance;
